<template>
	<v-layout column fill-height>
		<v-flex shrink>
			<ThemeAssignmentSectionHeader />
		</v-flex>
		<v-flex shrink>
			<v-divider />
		</v-flex>
		<v-flex scroll-y>
			<ThemeAssignmentMatrix />
		</v-flex>
	</v-layout>
</template>

<script>
import ThemesModuleGuard from '@/mixins/ModulesGuards/Themes/ThemesModuleGuard'

export default {
	name: 'ThemeAssignmentSection',
	components: {
		ThemeAssignmentMatrix: () => ({
			component: import('@/components/Themes/TeamManager/ThemeAssignmentMatrix')
		}),
		ThemeAssignmentSectionHeader: () => ({
			component: import('@/components/Themes/TeamManager/ThemeAssignmentSectionHeader')
		})
	},
	mixins: [ThemesModuleGuard],
	watch: {
		accountingFirmId: {
			handler: function (newValue, oldValue) {
				if (newValue && (!oldValue || newValue != oldValue)) {
					this.service.teamManager.store.reset()
					this.service.teamManager.loadThemes()
				}
			}
		}
	},
	created: function () {
		this.service.teamManager.loadThemes()
	},
	destroyed: function () {
		this.service.teamManager.store.reset()
	},
}
</script>
